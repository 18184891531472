@use 'base.scss';

.App {
    width: 86vw;
    // width: 100vw;
    max-width: 1920px;
    min-height: 100vh;
    position: relative;
}

.PageContainer {
    width: 100%;
    padding: 35px 0;
    color: base.$text;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.Lock {
    max-height: 100svh;
    min-height: unset;
    overflow: hidden;
    // position: fixed;
}

@media (max-width: 500px) {
    .App {
        width: 90vw;
        // width: 100vw;
    }
}