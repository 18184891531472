@use '../../styles/base.scss';

.Header {
    width: calc(100% - 36px - 705px);
    max-width: calc(1920px - 705px - 36px);
    padding: 35px 36px 35px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: base.$text;
    position: absolute;
    background-color: base.$header-bg;
    backdrop-filter: blur(5px);
    z-index: 1;
}

.HeaderLogo {
    margin-right: 20px;
    display: flex;
    flex-direction: row;

    img {
        width: 22px;
        margin-right: 12px;
        margin-top: 1px;
    }

    span {
        font-size: 22px;
        font-weight: 700;
    }
}

.HeaderNav {
    width: calc(100% - 167px - 20px - 10px);
    max-width: 500px;
    height: 46px;
    padding: 0 5px;
    background-color: base.$header;
    border-radius: 50px;
    box-shadow: base.$shadow;

    ul {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        li {
            font-size: 14px;
            font-weight: 500;
            width: calc(20% - 5px);
            height: 36px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 36px;
            cursor: pointer;
            transition: background-color .2s ease, color .2s ease;

            @media (hover: hover) {
                &:hover {
                    background-color: base.$violet-btn-hover;
                }
            }

            &.ChosenTab {
                background-color: base.$violet-btn;
                color: base.$text-stable;
            }
        }
    }
}

@media (min-width: 2250px) {
    .Header {
        width: calc(100% - 36px - 1000px);
        max-width: calc(1920px - 1000px - 36px);
    }
}

@media (max-width: 1600px) {
    .Header {
        width: 100%;
        max-width: 1920px;
        position: static;
        padding-bottom: 0;
        padding-right: 0;
    }

    .HeaderNav {
        width: 500px;
    }
}

@media (max-width: 760px) {
    .Header {
        justify-content: center;
    }
    
    .HeaderNav {
        display: none;
    }
}