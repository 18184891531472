@use '../../styles/base.scss';

.BurgerMenu {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: opacity 0.2s ease;
}

.BurgerMenuBox {
    width: 50px;
    height: 50px;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 50px;
    background-color: base.$header;
    transition: border-radius 0.2s ease, width 0.2s ease, height 0.2s ease, margin 0.2s ease;

    &.Active {
        width: 100vw;
        height: 100svh;
        margin-left: 0;
        margin-top: 0;
        border-radius: 0;
    }
}

.BurgerBtn {
    width: 50px;
    height: 50px;
    padding: 0;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition: top 0.2s ease, right 0.2s ease, left 0.2s ease;

    span {
        width: 14px;
        height: 1.75px;
        border-radius: 2px;
        background-color: base.$text;
        display: flex;
        position: absolute;
        top: calc(50% - 0.75px);
        transform: translateX(-2px);
        transition: width 0.2s ease;

        &::before {
            content: '';
            position: absolute;
            width: 18px;
            height: 1.75px;
            border-radius: 2px;
            transform: translateY(-6px);
            background-color: base.$text;
            transition: transform 0.2s ease;
        }

        &::after {
            content: '';
            position: absolute;
            width: 18px;
            height: 1.75px;
            border-radius: 2px;
            transform: translateY(6px);
            background-color: base.$text;
            transition: transform 0.2s ease;
        }
    }

    &.Active {
        left: calc(100% - 50px);

        span {
            width: 0;
            right: calc(50% + 9px);

            &::before {
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }
        }
    }
}

@media (min-width: 761px) {
    .BurgerMenu {
        display: none;
    }
}