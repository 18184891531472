@use '../../../styles/base.scss';

.ContactsSub {
    font-size: 48px;
    font-weight: 500;
    align-self: flex-start;
    color: base.$violet-btn;
    margin-bottom: 16px;
}

.ContactsRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.SendForm {
    width: 55%;
}

.SendFormRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.SendFormRow+.SendFormRow {
    margin-top: 16px;
}

.ContactsInputBox {
    position: relative;
    width: calc(50% - 6px);
    display: flex;
}

.ContactsInputBoxBig {
    margin-top: 16px;
    position: relative;
    width: 100%;
    display: flex;

    .ContactsInput {
        min-height: 100px;
        max-height: 200px;
        resize: vertical;
    }
}

.ContactsInput {
    padding: 16px 26px;
    width: calc(100% - 52px);
    border: none;
    background-color: base.$violet-btn-hover-light;
    color: base.$violet-dark;
    font-size: 14px;
    font-weight: 500;
    border-radius: 26px;
    box-shadow: base.$shadow;
    outline: none;
    font-family: 'Manrope', sans-serif;
}

.ContactsInputPlaceholder {
    position: absolute;
    left: 22px;
    top: -8px;
    background-color: base.$violet-btn-hover-light;
    color: base.$violet-dark-light;
    font-size: 12px;
    font-weight: 500;
    padding: 0 3px;
    border-radius: 10px;
    transition: font-size 0.2s ease, transform 0.2s ease;
    pointer-events: none;
}

.ContactsCanTransform {
    font-size: 14px;
    transform: translateY(24px);
}

.ContactsInput:focus+.ContactsInputPlaceholder {
    transform: unset !important;
    font-size: 12px;
}

.ContactsInput+.ContactsInput {
    margin-top: 10px;
}

.ContactsSendRow {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ContactsSendBtn {
    width: fit-content;
    height: 50px;
    padding: 0px 26px;
    display: flex;
    align-items: center;
    background-color: base.$violet;
    color: white;
    border-radius: 40px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
    box-shadow: base.$shadow;
    
    span {
        width: fit-content;
        position: relative;
    }

    @media (hover: hover) {
        &:hover {
            background-color: base.$violet-light;
        }

        &:active {
            transform: scale(0.98);
        }
    }

    @media (pointer: coarse) {
        &:active {
            background-color: base.$violet-light;
        }
    }
}

.ContactsStatus {
    margin-left: 16px;
    font-size: 12px;
    display: flex;
    color: base.$violet-flex;

    &.Error {
        color: base.$error-flex;
    }
}

.MailSubscribe {
    width: calc(30% - 26px);
    height: 361px;
    padding: 26px;
    border-radius: 26px;
    background-image: url('../../../assets/images/contacts_bg.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: base.$shadow;
}

.MailSubscribeSub {
    color: white;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 6px;
}

.MailSubscribePar {
    color: white;
    font-size: 16px;
    margin-bottom: 24px;
}

.NewsInputBox {
    position: relative;
    display: flex;
    margin-bottom: 16px;

    .ContactsInput,
    .ContactsInputPlaceholder {
        background-color: white;
        box-shadow: unset;
    }
}

.NewsSendBtn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: base.$violet-dark;
    color: white;
    border-radius: 40px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;

    @media (hover: hover) {
        &:hover {
            background-color: base.$violet-mid;
        }

        &:active {
            transform: scale(0.98);
        }
    }

    @media (pointer: coarse) {
        &:active {
            background-color: base.$violet-light;
        }
    }
}

.ContactsCards {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ContactCard {
    width: calc(25% - 52px - 20px);
    padding: 26px;
    border-radius: 26px;
    box-shadow: base.$shadow;

    &.First {
        background-color: base.$violet-mid;
        color: white;

        .ContactCardPar {
            color: base.$violet-btn-hover-light;
        }
    }

    &.Second {
        background-color: base.$violet-card;
        color: white;

        .ContactCardPar {
            color: base.$violet-btn-hover-light;
        }
    }

    &.Third {
        background-color: base.$violet-btn-hover-light;
        color: base.$violet-dark;

        .ContactCardPar {
            color: base.$violet-dark-light2;
        }
    }

    &.Fourth {
        background-color: base.$violet-lightest;
        color: base.$violet-dark;

        .ContactCardPar {
            color: base.$violet-dark-light3;
        }
    }
}

.ContactCardTop {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    img {
        width: 40px;
    }

    a {
        margin-left: 16px;
        font-size: 18px;
        font-weight: 900;
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
            }
        }

        @media (pointer: coarse) {
            &:active {
                text-decoration: underline;
            }
        }
    }
}

.ContactCardPar {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 1680px) {
    .ContactsCards {
        width: calc(100% + 20px);
        margin-left: -20px;
    }

    .ContactCard {
        width: fit-content;
        margin-left: 20px;
    }
}

@media (max-width: 1570px) {
    .MailSubscribe {
        width: 35%;
    }
}

@media (max-width: 1470px) {
    .ContactsCards {
        flex-wrap: wrap;
        width: 100%;
        margin-left: 0;
        margin-top: 24px;
    }

    .ContactCard {
        margin-left: 0;
        width: calc(50% - 52px - 8px);
        margin-top: 16px;
    }
}

@media (max-width: 1000px) {
    .ContactsRow {
        flex-direction: column;
    }

    .SendForm {
        width: 100%;
    }

    .MailSubscribe {
        width: calc(100% - 52px);
        height: fit-content;
        padding: 40px 26px;
        margin-top: 40px;
    }
}

@media (max-width: 780px) {
    .ContactCard {
        width: calc(100% - 56px);
    }
}

@media (max-width: 756px) {
    .SendFormRow {
        flex-direction: column;
    }

    .ContactsInputBox {
        width: 100%;
    }

    .ContactsInputBox+.ContactsInputBox {
        margin-top: 12px;
    }

    .SendFormRow+.SendFormRow {
        margin-top: 12px;
    }

    .ContactsInputBoxBig {
        margin-top: 12px;
    }
}

@media (max-width: 660px) {
    .ContactsSub {
        font-size: 28px;
    }

    .SendForm {
        display: flex;
        flex-direction: column;
    }
    
    .ContactsSendBtn {
        font-weight: 500;
        align-self: flex-end;
    }

    .ContactsSendRow {
        align-self: flex-end;
        width: 100%;
        flex-direction: column;
    }

    .ContactsStatus {
        margin-left: 0;
        margin-top: 0;

        &.Error {
            margin-top: 10px;
        }
    }

    .ContactsSendBtn {
        padding: 0;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .NewsInputBox {
        margin-bottom: 6px;
    }
    
    .NewsSendBtn {
        font-weight: 500;
    }

    .MailSubscribe {
        border-radius: 20px;
        width: calc(100% - 20px);
        height: fit-content;
        padding: 40px 10px;
    }

    .MailSubscribeSub {
        font-size: 18px;
    }

    .MailSubscribePar {
        font-size: 14px;
        margin-bottom: 16px;
    }

    .ContactsCards {
        margin-top: 30px;
    }
    
    .ContactCard {
        border-radius: 20px;
        margin-top: 10px;
        width: calc(100% - 20px);
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    .ContactCardTop {
        margin-bottom: 10px;

        img {
            width: 28px;
        }

        a {
            font-size: 14px;
            margin-left: 10px;
        }
    }

    .ContactCardPar {
        font-size: 12px;
    }

    .ContactsInputPlaceholder {
        font-weight: 400;
    }
}

@media (max-width: 650px) {
    .ContactsCards {
        width: 100vw;
        align-self: center;
    }

    .ContactCard {
        border-radius: 20px;
        width: calc(100% - 10vw);
        padding: 10px 5vw;
        border-radius: unset;
    }

    .ContactCardTop {
        img {
            width: 32px;
        }

        a {
            font-size: 17px;
        }
    }
}