@use '../../styles/base.scss';

.PrivacyContainer {
    a {
        color: base.$violet !important;
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
            }
        }

        @media (pointer: coarse) {
            &:active {
                text-decoration: underline;
            }
        }
    }

    .HeaderLogo {
        cursor: pointer;
        margin-top: 8px;
    }

    .MT30 {
        margin-top: 30px;
    }

    h2 {
        margin-top: 40px;
        font-size: 22px;
    }

    h3 {
        font-weight: 600;
        margin-top: 40px;
        font-size: 18px;
    }

    p {
        margin: 0;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.015em;
    }

    ul {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.015em;
    }

    table {
        border-collapse: collapse;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.015em;
        border: solid 1px rgba(#808080, 0.3);

        td,
        th {
            border: solid 1px rgba(#808080, 0.3);
            padding: 2px 10px;
            text-align: left;
            vertical-align: top;
        }

        th {
            font-weight: 600;
        }

        ul {
            list-style: disc;
            padding-left: 16px;

            li {
                list-style: disc;
            }
        }
    }
}