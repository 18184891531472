@use '../../../styles/base.scss';

.MainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.MainInfo {
    width: calc(100% - 705px - 36px);
    height: 100%;
}

.MainInfoSub {
    margin-top: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        width: 5px;
        margin-top: 1px;
        margin-right: 6px;

        circle {
            fill: base.$text;
            opacity: 0.1;
        }
    }

    span {
        font-size: 16px;
        font-weight: 500;
    }
}

.MainInfoPar {
    margin: 30px 0 0 0;
    font-size: 16px;
    font-weight: 400;
}

.MainInfoBtns {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
}

.MainInfoCasesBtn {
    height: 43px;
    width: fit-content;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background-color: base.$violet-btn;
    border: solid 1.5px base.$violet-btn;
    color: base.$text-stable;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
    box-shadow: base.$shadow;

    img {
        margin-left: 10px;
        margin-top: 2px;
        width: 14px;
    }

    @media (hover: hover) {
        &:hover {
            background-color: base.$violet-btn-hover-light;
        }

        &:active {
            transform: scale(0.98);
        }
    }

    @media (pointer: coarse) {
        &:active {
            background-color: base.$violet-btn-hover-light;
        }
    }
}

.MainInfoContactBtn {
    margin-left: 27px;
    height: 43px;
    border: solid 1.5px base.$text;
    color: base.$text;
    width: fit-content;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
    box-shadow: base.$shadow;

    img {
        margin-left: 16px;
        width: 14px;
    }

    path {
        fill: base.$text;
    }

    @media (hover: hover) {
        &:hover {
            background-color: base.$violet-btn-hover;
        }

        &:active {
            transform: scale(0.98);
        }
    }

    @media (pointer: coarse) {
        &:active {
            background-color: base.$violet-btn-hover;
        }
    }
}

.MainInfoCircles {
    margin-top: 30px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;

    .Circle1 {
        width: 47px;
        height: 47px;
        background-color: #C6BDFC80;
        border-radius: 50px;
        border: solid 1.5px base.$border;
        z-index: -1;
    }

    .Circle2 {
        width: 47px;
        height: 47px;
        background-color: #C6BDFC;
        border-radius: 50px;
        border: solid 1.5px base.$border;
        transform: translate(-25px, 0);
        z-index: -1;
    }

    .Circle3 {
        width: 47px;
        height: 47px;
        background-color: #8576DF;
        border-radius: 50px;
        border: solid 1.5px base.$border;
        transform: translate(-50px, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;

        img {
            width: 30px;
        }
    }
}

.MainInfoDigits {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.MainInfoDigit {
    display: flex;
    flex-direction: column;
}

.MainInfoDigit+.MainInfoDigit {
    margin-left: 40px;
}

.MainInfoDigitSub {
    font-size: 75px;
    font-weight: 600;
    width: 213px;
    color: base.$text;

    &.SubLeft {
        margin-left: -10px;
        width: 223px;
    }
}

.MainInfoDigitTip {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.5;
}

.MainInfoSlogan {
    font-size: 70px;
    font-weight: 600;
    line-height: 80px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.MainInfoLine {
    width: 100%;
    height: 1px;
    background-color: base.$line;
    z-index: -1;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        top: calc(50% - 2.5px);
        left: 0;
        background-color: base.$line;
    }

    &::after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        top: calc(50% - 2.5px);
        right: 0;
        background-color: base.$line;
    }
}

.MainBanner {
    width: 705px;
    height: calc(1080px - 70px - 70px);
}

.MainBannerBox {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 36px;
    background-image: url('../../../assets/images/cubes4.jpg');
    background-position: center;
    background-size: cover;
}

.MainBannerContacts {
    padding: 5px;
    width: fit-content;
    height: fit-content;
    display: flex;
    border-radius: 40px;
    background-color: base.$banner;
    backdrop-filter: blur(3px);

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background-color: white;
        color: base.$text-stable;
        border-radius: 30px;
    }

    a+a {
        margin-left: 9px;
    }

    .MainBannerContactTg {
        margin-top: 1px;
        margin-right: 2px;
    }

    .MainBannerContactWhatsapp {
        margin-bottom: 1px;
    }

    img {
        cursor: pointer;
        transition: opacity 0.2s ease;

        @media (hover: hover) {
            &:hover {
                opacity: 0.8;
            }
        }

        @media (pointer: coarse) {
            &:active {
                opacity: 0.8;
            }
        }
    }
}

.MainBannerBottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.MainBannerSticks {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.StickTip {
    padding: 1px 10px;
    font-size: 12px;
    font-weight: 400;
    color: white;
    width: fit-content;
    background-color: base.$banner;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: -10px;
}

.MainBannerStickBox+.MainBannerStickBox {
    margin-left: 19px;
}

.MainBannerStick {
    background-color: base.$banner;
    padding: 10px;
    border-radius: 16px;
    border-top-left-radius: 0;

    p {
        width: 189px;
        margin: 0 0 10px 0;
        font-size: 15px;
        font-weight: 400;
        color: white;
    }
}

.StickBtn {
    height: 36px;
    padding: 0 4px 0 16px;
    width: fit-content;
    background-color: white;
    color: base.$text-stable;
    border-radius: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;

    @media (hover: hover) {
        &:hover {
            background-color: base.$violet-btn-hover-light;
        }
    }

    @media (pointer: coarse) {
        &:active {
            background-color: base.$violet-btn-hover-light;
        }
    }

    img {
        height: 28px;
    }

    span {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 500;
    }
}

.StickBtn+.StickBtn {
    margin-top: 6px;
}

.StickListItem {
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        width: 12px;
        margin-top: 1px;
        margin-right: 7px;
    }

    span {
        color: white;
        font-size: 12px;
        font-weight: 400;
    }
}

.StickDiagram {
    width: 195px;
    margin-top: 16px;
}

.MainBannerSigns {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.MainLearnBtn {
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    width: 166px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: base.$text-stable;
    border-radius: 50px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;

    @media (hover: hover) {
        &:hover {
            background-color: base.$violet-btn;
        }

        &:active {
            transform: scale(0.98);
        }
    }

    @media (pointer: coarse) {
        &:active {
            background-color: base.$violet-btn;
        }
    }

    img {
        width: 12px;
        margin-left: 12px;
        margin-top: 1px;
    }
}

.MainSign {
    width: 166px;
    height: 40px;
    color: white;
    background-color: rgba(base.$violet, 0.4);
    background-color: base.$banner;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
}

@media (min-width: 2250px) {
    .MainInfo {
        width: calc(100% - 1000px - 36px);
    }

    .MainBanner {
        width: 1000px;
    }
}

@media (max-width: 1600px) {
    .MainInfoSub {
        margin-top: 0;
    }
}

@media (max-width: 1380px) {
    .MainContainer {
        flex-direction: column;
    }

    .MainInfo {
        width: 100%;
    }

    .MainBanner {
        height: fit-content;
        width: 100%;
        margin-top: 30px;
    }

    .MainBannerBox {
        background-image: url('../../../assets/images/cubes5.jpg');
    }

    .MainBannerBottom {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .MainBannerSticks {
        flex-direction: row-reverse;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
    }

    .MainBannerStick {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 18px - 20px);

        p {
            width: 100%;
        }
    }

    .StickBtn {
        height: 44px;
        width: calc(100% - 24px);
        padding: 0 4px 0 20px;
        justify-content: space-between;

        img {
            width: 37px;
            height: 37px;
        }
    }

    .StickDiagram {
        align-self: center;
    }

    .MainBannerStickBox {
        margin-left: 0 !important;
        width: calc(50% - 10px);
    }

    .MainBannerSigns {
        margin-top: 10px;
        align-self: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .MainLearnBtn {
        margin-top: 10px;
        height: 44px;
        flex: 1 1 150px;
        display: flex;
        align-items: center;

        img {
            margin-left: 10px;
        }
    }

    .MainSign {
        margin-top: 6px !important;
        flex: 1 1 150px;
        height: 44px;
    }
}

@media (max-width: 650px) {
    .MainInfo {
        display: flex;
        flex-direction: column;
    }

    .MainInfoSub {
        span {
            font-size: 12px;
        }
    }

    .MainInfoSlogan {
        font-size: 46px;
        font-weight: 700;
        line-height: 52px;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .MainInfoPar {
        font-size: 12px;
        font-weight: 500;
        line-height: 160%;
        margin: 16px 0 0 0;
    }

    .MainInfoBtns {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .MainInfoCasesBtn {
        font-size: 14px;

        img {
            margin-top: 0;
        }
    }

    .MainInfoContactBtn {
        font-size: 14px;

        img {
            margin-bottom: 1px;
            margin-left: 10px;
        }
    }

    .Circle1,
    .Circle2,
    .Circle3 {
        width: 36px !important;
        height: 36px !important;

        img {
            width: 20px !important;
        }
    }

    .MainInfoDigitSub {
        font-size: 58px;
    }

    .MainInfoDigitTip {
        font-size: 12px;
        font-weight: 500;
    }

    .MainInfoCircles {
        margin-top: 16px;
    }

    .MainInfoDigits {
        margin-bottom: 16px;
    }

    .MainBannerSticks {
        flex-direction: column;
    }

    .MainBannerStickBox {
        width: 100%;
    }

    .MainBannerStickBox+.MainBannerStickBox {
        margin-top: 16px;
    }

    .MainBannerBox {
        border-radius: 20px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 10px;
    }

    .StickTip {
        font-size: 10px;
        font-weight: 500;
        padding: 2px 10px;
    }

    .MainBannerStick {
        p {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .MainBanner {
        height: fit-content;
        width: 100vw;
        align-self: center;
        // margin-left: -5vw;
        // padding: ;
        margin-top: 30px;
    }

    .MainBannerBox {
        padding: 30px 5vw;
        width: calc(100% - 10vw);
        border-radius: unset;
    }
}

@media (max-width: 530px) {
    .MainInfoSlogan {
        font-size: 36px;
        line-height: 40px;
        text-align: center;
    }

    .MainInfoDigit {
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0 !important;
    }

    .MainInfoDigitSub {
        width: 130px;

        &.SubLeft {
            width: 130px;
        }
    }

    .MainInfoDigitTip {
        text-align: center;
        width: 100%;
    }

    .MainInfoDigits {
        justify-content: space-between;
    }

    .MainInfoBtns {
        justify-content: space-between;
    }

    .MainInfoContactBtn {
        margin-left: 0;
    }
}

@media (max-width: 390px) {
    .MainInfoBtns {
        flex-direction: column;
    }

    .MainInfoCasesBtn,
    .MainInfoContactBtn {
        width: calc(100% - 44px);
        justify-content: center;
    }

    .MainInfoContactBtn {
        margin-top: 10px;
    }
}