@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Manrope:wght@200..800&display=swap');

:root {
    --text: #ebebeb;
    --text-half: #ebebeb4d;
    --header: #232323;
    --header-bg: rgba(#181818, 0.9);
    --line: #232323;
    --line-bright: #333333;
    --border: #181818;
    --shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    --prices: #362c72;
    --progress: #C6BDFC;
    --inactive: #808080;
    --check: #ffffff;
    --submit: #ffffff;
    --light-btn-bg: #ffffffb3;
    --orange-flex: #ffb168;
    --background: #181818;
    --violet-flex: #a094e8;
    --error-flex: #ffb168;
}

$text: var(--text);
$text-half: var(--text-half);
$header: var(--header);
$header-bg: var(--header-bg);
$line: var(--line);
$line-bright: var(--line-bright);
$border: var(--border);
$shadow: var(--shadow);
$prices: var(--prices);
$progress: var(--progress);
$inactive: var(--inactive);
$check: var(--check);
$submit: var(--submit);
$light-btn-bg: var(--light-btn-bg);
$orange-flex: var(--orange-flex);
$background: var(--background);
$violet-flex: var(--violet-flex);
$error-flex: var(--error-flex);

$text-stable: #232323;
$violet-btn: #C6BDFC;
$violet-btn-hover: rgba($violet-btn, 0.4);
$violet-btn-hover-light: #e1dcff;
$violet-lightest: #f6f5ff;
$violet: #8576DF;
$violet-light: #a094e8;
$violet-mid: #413689;
$violet-mid-hover: #544d87;
$violet-dark: #231C4C;
$violet-darkest: #1b163a;
$violet-dark-light: rgba($violet-dark, 0.6);
$violet-dark-light2: rgba($violet-dark, 0.8);
$violet-dark-light3: rgba($violet-dark, 0.7);
$violet-card: #6a5db1;
$banner: rgba($violet-darkest, 0.8);
$orange: #ff7b00;
$orange-light: #ffb168;
$orange-very-light: #ffd7b2;

html::-webkit-scrollbar,
body::-webkit-scrollbar {
    width: 10px;
    background-color: $header;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
    background-color: $violet-btn;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
    background-color: #f9f9fd;
    transition: background-color 0.3s ease;
}

ul {
    display: flex;
    flex-direction: row;
}

li {
    list-style-type: none;
}

button {
    background-color: unset;
    border-radius: unset;
    border: unset;
    cursor: pointer;
}

a {
    text-decoration: unset;
    color: unset;
}

body {
    background-color: #181818;
    margin: 0;
    width: 100vw;
    overflow-x: hidden;
    font-family: 'Manrope', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.LightTheme {
        background-color: #ffffff;
    }

    &.DarkTheme {
        background-color: #181818;
    }
}

html {
    width: 100vw;
    overflow-x: hidden;
}