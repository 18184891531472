@use '../../../styles/base.scss';

.AboutSub {
    font-size: 36px;
    font-weight: 700;
}

.AboutPars {
    width: 100%;
    max-width: 1428px;
    display: flex;
    flex-direction: column;
}

.AboutPar {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
}

.AboutPar1 {
    margin-top: 20px;
    font-size: 56px;
    font-weight: 400;
    display: flex;
    align-items: center;

    span {
        background-color: #948bfa80;
        font-size: 80px;
        font-weight: 500;
        color: base.$text;
        text-transform: uppercase;
    }
}

.AboutPar2 {
    font-size: 80px;
    font-weight: 400;
    align-self: flex-end;
    position: relative;

    span {
        background-color: #948bfa80;
        font-size: 80px;
        font-weight: 500;
        color: base.$text;
        text-transform: uppercase;
    }

    &::before {
        content: 'и';
        position: absolute;
        font-size: 36px;
        left: -44px;
        top: -30px;
    }
}

.AboutHelp {
    margin-top: 42px;
    font-size: 28px;
    border: dashed 1.5px base.$text;
    width: fit-content;
    padding: 6px 20px;
    border-radius: 50px;
    transform: rotate(-3deg);
    align-self: center;
    box-shadow: base.$shadow;
}

.AboutArr {
    align-self: center;
    display: flex;
    margin-top: 28px;

    svg {
        height: 40px;
    }

    rect {
        stroke: base.$text;
    }

    path {
        fill: base.$text;
    }
}

.AboutPar3 {
    margin-top: 36px;
    font-size: 86px;
    font-weight: 400;
    align-self: center;

    span {
        background-color: #b46dff80;
        color: base.$text;
        font-weight: 500;
        text-transform: uppercase;
    }
}

.AboutPar4 {
    margin-top: 36px;
    font-size: 62px;

    span {
        background-color: #8B5CF680;
        font-weight: 500;
        color: base.$text;
        text-transform: uppercase;
    }
}

.AboutPar5 {
    margin-top: 36px;
    font-size: 36px;
    font-weight: 400;
    align-self: flex-end;

    span {
        background-color: #6424cb80;
        font-size: 48px;
        font-weight: 500;
        color: base.$text;
        text-transform: uppercase;
    }
}

.AboutCards {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.AboutCard {
    width: calc(50% - 52px);
    padding: 16px;
    border: solid 1px base.$line;
    border-radius: 16px;
    box-shadow: base.$shadow;
    background-color: base.$violet-btn-hover;
}

.AboutCardSub {
    font-size: 28px;
    font-weight: 500;
}

.AboutCardPar {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 500;
    display: flex;

    .AboutCardMarker {
        width: 24px;
    }
}

.AboutCardMicroPar {
    font-size: 14px;
    margin-left: 24px;
    margin-top: 6px;
    opacity: 0.7;
}

.AboutWorkSub {
    margin-top: 40px;
    font-size: 42px;
    align-self: center;
    color: base.$violet-btn;
}

.AboutMicroCards {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.AboutMicroCard {
    width: calc(25% - 32px - 24px);
    padding: 26px 16px;
    border: dashed 1.5px base.$text-half;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    box-shadow: base.$shadow;
}

.AboutMicroCardNum {
    font-size: 80px;
    font-weight: 300;
    opacity: 0.4;
    color: base.$violet;
    position: absolute;
}

.AboutMicroCardRight1 {
    margin-left: 47px;
}

.AboutMicroCardRight2 {
    margin-left: 62px;
}

.AboutMicroCardRight3 {
    margin-left: 60px;
}

.AboutMicroCardRight4 {
    margin-left: 63px;
}

.AboutMicroCardSub {
    font-size: 18px;
    font-weight: 500;
    display: flex;

    .AboutCardMarker {
        width: 24px;
    }
}

.AboutMicroCardPar {
    font-size: 14px;
    margin-top: 6px;
    opacity: 0.7;
}

@media (max-width: 1680px) {
    .AboutPar1 {
        align-self: center;
    }

    .AboutPar2 {
        align-self: center;
        margin-top: 60px;

        &::before {
            left: calc(50% - 20px);
            top: -62px;
        }
    }
}

@media (max-width: 1400px) {
    .AboutMicroCards {
        flex-wrap: wrap;
        margin-top: -10px;
    }

    .AboutMicroCard {
        width: calc(50% - 32px - 20px);
        margin-top: 30px;
    }
}

@media (max-width: 950px) {
    .AboutPar1 {
        font-size: 36px;

        span {
            font-size: 60px;
        }
    }

    .AboutPar2 {
        margin-top: 40px;
        font-size: 60px;

        &::before {
            font-size: 28px;
            top: -42px;
            left: calc(50% - 15px);
        }

        span {
            font-size: 60px;
        }
    }

    .AboutPar3 {
        font-size: 66px;
    }

    .AboutPar4 {
        font-size: 44px;
        margin-top: 28px;
    }

    .AboutPar5 {
        font-size: 28px;
        text-align: end;
        margin-top: 28px;

        span {
            font-size: 36px;
        }
    }
}

@media (max-width: 800px) {
    .AboutCards {
        flex-direction: column;
    }

    .AboutCard {
        width: calc(100% - 34px);
    }

    .AboutCard+.AboutCard {
        margin-top: 20px;
    }
}

@media (max-width: 700px) {
    .AboutMicroCards {
        flex-direction: column;
    }

    .AboutMicroCard {
        width: calc(100% - 34px);
        max-width: 300px;
    }
}

@media (max-width: 660px) {
    .AboutPar1 {
        font-size: 24px;

        span {
            font-size: 36px;
        }
    }

    .AboutPar2 {
        margin-top: 30px;
        font-size: 36px;

        &::before {
            font-size: 18px;
            top: -30px;
            left: calc(50% - 10px);
        }

        span {
            font-size: 36px;
        }
    }

    .AboutHelp {
        font-size: 18px;
        margin-top: 28px;
    }

    .AboutArr {
        margin-top: 18px;

        svg {
            height: 30px;
        }
    }

    .AboutPar3 {
        margin-top: 18px;
        font-size: 36px;
    }

    .AboutPar4 {
        font-size: 24px;
        margin-top: 18px;
    }

    .AboutPar5 {
        font-size: 18px;
        margin-top: 18px;

        span {
            font-size: 24px;
        }
    }

    .AboutCardSub {
        font-size: 20px;
        font-weight: 600;
    }

    .AboutCardPar {
        font-size: 14px;
        font-weight: 600;

        .AboutCardMarker {
            width: 16px;
        }
    }

    .AboutCardMicroPar {
        font-size: 12px;
        margin-left: 16px;
    }

    .AboutWorkSub {
        font-size: 28px;
        font-weight: 500;
    }

    .AboutMicroCardSub {
        font-size: 14px;
        font-weight: 600;
    }

    .AboutMicroCardPar {
        font-size: 12px;
    }

    .AboutMicroCards {
        margin-top: 6px;
    }

    .AboutMicroCard {
        margin-top: 10px;
    }
}

@media (max-width: 650px) {
    .AboutCard {
        width: 90vw;
        padding: 16px 5vw;
        align-self: center;
        border-radius: unset;
    }
}