@use '../../../styles/base.scss';

.PricesContainer {
    padding: 35px;
    width: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: base.$prices;
    border-radius: 36px;
    box-shadow: base.$shadow;
}

.PricesSub {
    font-size: 42px;
    font-weight: 500;
    color: white;
    position: relative;

    .PricesSubBlob {
        position: absolute;
        top: -6px;
        right: -28px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 45px;
        }

        span {
            color: base.$violet-dark;
            position: absolute;
            font-size: 14px;
            margin-bottom: 3px;
            transform: rotate(20deg);
        }
    }
}

.PricesBox {
    margin-top: 36px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.PriceCard {
    width: calc(100% / 3 - 70px);
    padding: 20px;
    border: solid 4px white;
    border-radius: 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: box-shadow 0.2s ease;

    @media (hover: hover) {
        &:hover {
            box-shadow: 0 0 20px 0 rgba(#231C4C, 0.5);
        }
    }
}

.PriceCard.Focused {
    animation: borderRate 3s;
}

@keyframes borderRate {
    0% {
        border: solid 4px base.$orange;
    }

    90% {
        border: solid 4px base.$orange;
    }

    100% {
        border: solid 4px white;
    }
}

.OnePriceCard {
    margin-top: 40px;
    width: calc(100% - 50px + 2px) !important;
    flex-direction: row;

    .PriceCardBottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }

    .OnePriceCardBottom {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

.PremiumCardM {
    display: none;
}

.SupportCardM {
    display: none;
}

.PriceCardSub {
    font-size: 24px;
    font-weight: 600;
    color: base.$violet-dark;
}

.PriceCardDescription {
    margin-top: 6px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.5;
    color: base.$text-stable;
}

.PriceCardPoints {
    margin-top: 16px;
}

.PriceCardPoint {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: base.$text-stable;

    img {
        width: 7px;
        margin-right: 10px;
        margin-top: 4px;
    }

    span {
        font-size: 14px;
        font-weight: 500;
        width: calc(100% - 18px);
    }
}

.PriceCardPoint+.PriceCardPoint {
    margin-top: 6px;
}

.PriceCardTime {
    margin-top: 20px;
    font-size: 12px;
    color: base.$text-stable;
}

.PriceCardPrice {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 10px;
}

.PriceBefore {
    font-size: 16px;
    opacity: 0.5;
    margin-bottom: 4px;
    margin-left: 3px;
    margin-right: 10px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: calc(100% + 6px);
        height: 1px;
        top: 50%;
        left: -3px;
        background-color: white;
    }
}

.PriceActual {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: base.$text-stable;
}

.PriceMonth {
    align-self: flex-start;
    margin-top: 9px;
    margin-left: 3px;
    font-size: 12px;
    color: base.$text-stable;
}

.PriceTip {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 4.5px;
    margin-right: 8px;
    color: base.$text-stable;
}

.PriceLine {
    width: 100%;
    height: 0.5px;
    background-color: rgba(base.$violet-dark, 0.2);
}

.PriceCardBtn {
    margin-top: 10px;
    width: fit-content;
    padding: 0 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: base.$violet-dark;
    color: white;
    border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease, border 0.2s ease, transform 0.2s ease;

    @media (hover: hover) {
        &:hover {
            background-color: base.$violet-mid;
            color: white !important;
        }

        &:active {
            transform: scale(0.98);
        }
    }

    @media (pointer: coarse) {
        &:active {
            background-color: base.$violet-mid;
            color: white !important;
        }
    }
}

@media (max-width: 1300px) {
    .PriceCard {
        width: calc(100% / 2 - 68px);
    }

    .PremiumCardPC {
        display: none;
    }

    .PremiumCardM {
        display: flex;
    }
}

@media (max-width: 1080px) {
    .PricesBox {
        flex-wrap: wrap;
        margin-top: 12px;
    }

    .PriceCard {
        margin-top: 24px;
        width: calc(100% / 2 - 62px);
    }

    .SupportCardM {
        display: flex;
    }

    .PremiumCardPC {
        display: flex;
    }

    .PremiumCardM {
        display: none;
    }

    .SupportCardPC {
        display: none;
    }
}

@media (max-width: 860px) {
    .PriceCard {
        width: calc(100% - 44px);
    }
}

@media (max-width: 650px) {
    .PricesContainer {
        border-radius: unset;
        padding: 16px 5vw 30px 5vw;
        width: calc(100% - 20px);
        width: calc(90vw);
    }

    .PricesSub {
        font-size: 36px;
        font-weight: 500;
    }

    .PricesBox {
        margin-top: 0px;
    }

    .PriceCard {
        margin-top: 16px;
        width: calc(100% - 32px);
        padding: 10px 16px;
        border-radius: 16px;
    }

    .PriceCardSub {
        font-size: 18px;
    }

    .PriceCardDescription {
        font-size: 12px;
    }

    .PriceTip {
        font-size: 16px;
        margin-bottom: 2.5px;
    }

    .PriceActual {
        font-size: 24px;
    }

    .PriceMonth {
        font-size: 10px;
        margin-top: 6px;
    }

    .PriceCardPoint {
        img {
            width: 5px;
            margin-top: 5px;
            margin-right: 6px;
        }

        span {
            font-size: 12px;
        }
    }

    .PriceCardTime {
        font-size: 10px;
    }

    .PriceCardBtn {
        margin-top: 6px;
    }

    .PriceCardBtn {
        height: 44px;
        font-size: 14px;
        font-weight: 500;
    }
}