@use '../../styles/base.scss';

.BottomMenuContainer {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 7vw;
    max-width: 314px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    transition: opacity 0.3s ease;
}

.BottomMenuBox {
    margin-bottom: 35px;
    width: 50px;
    padding: 5px 0;
    background-color: base.$header;
    box-shadow: base.$shadow;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BottomMenuTab {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color .2s ease, box-shadow .2s ease, height .2s ease;
    position: relative;

    @media (hover: hover) {
        &:hover {
            background-color: base.$violet-btn-hover;

            .MenuTip {
                opacity: 1;
            }
        }
    }

    &.ChosenTab {
        background-color: base.$violet-btn;
    }

    &.ChosenTabLight {
        background-color: base.$text-stable;
        box-shadow: inset 0 0 20px 2px rgba(base.$violet-btn, 0.5);
    }

    img {
        height: 20px;
    }
}

.BottomMenuTab+.BottomMenuTab {
    margin-top: 25px;
}

.ThemeButton {
    width: 40px;
    height: 40px;
    background-color: white;
}

@media (min-width: 761px) {
    .ScrollTab {
        height: 65px;
        transition: height .2s ease, opacity .2s ease;

        &.InactiveTab {
            height: 0;
            opacity: 0;
            pointer-events: none;
        }
    }
}

@media (min-width: 2233px) {
    .BottomMenuContainer {
        right: calc(50vw - 157px - 960px);
    }
}

@media (max-width: 760px) {
    .BottomMenuContainer {
        width: 50px;
        right: 10px;
        right: unset;
        left: 10px;
        padding: unset;
    }

    .BottomMenuBox {
        width: 50px;
        padding: 0;
        margin-bottom: 10px;
    }

    .BottomMenuTab {
        width: 50px;
        height: 50px;

        img {
            height: 16px;
        }
    }

    .InvisibleMobile {
        opacity: 0;
        pointer-events: none;
    }

    .BottomPCTab {
        display: none;
    }
}